<template>
  <div style="width: 250px" class="left-side-menu">
    <div class="h-100" data-simplebar>
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <ul id="side-menu">
          <!-- <li v-if="hasPermissions(['trainer-list'])">
            <a href="#dashboard-management" data-bs-toggle="collapse" :aria-expanded="isActive(['/admin/dashboard'])">
              <i class="fas fa-solid fa-chart-line fs-3"></i>
              <span> Dashboard </span>
              <span class="menu-arrow"></span>
            </a>
            <div class="collapse" id="dashboard-management" :class="{ 'show': isActive(['/admin/dashboard']) }">
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link class="nav-link" :to="{ name: 'dashboard' }">
                    <span :class="{ activeRoute: $route.name == 'dashboard' }">
                      Dashboard </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li> -->

          <li
            v-if="
              hasPermissions([
                'trainer-list',
                'program-owner-list',
                'program-owner-handover-list',
                'program-handover-approval-list',
              ])
            "
          >
            <!-- id="trainers" -->
            <a
              href="#trainer-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/trainers',
                  '/admin/program-owners',
                  '/admin/program-owner-handover',
                  '/admin/program-handover-approval',
                ])
              "
            >
              <i class="fas fa-chalkboard-teacher"></i>
              <span> Trainer Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="trainer-management"
              :class="{
                show: isActive([
                  '/admin/trainers',
                  '/admin/program-owners',
                  '/admin/program-owner-handover',
                  '/admin/program-handover-approval',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link class="nav-link" :to="{ name: 'trainers' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-create' ||
                          $route.name == 'trainer-update',
                      }"
                    >
                      Trainers
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'program-owners' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'program-owner-create' ||
                          $route.name == 'program-owner-update',
                      }"
                    >
                      Program Owners
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-handover-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'program-owner-handover' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'program-owner-handover-create' ||
                          $route.name == 'program-owner-handover-detail',
                      }"
                    >
                      Program Owner Handover
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-handover-approval-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'program-handover-approval' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'program-handover-approval-update',
                      }"
                    >
                      Handover Approval
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li>
            <a
              href="#trainer-profile-list"
              data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/trainer-profile'])"
            >
              <i class="mdi mdi-account-box fs-3"></i>
              <span> Trainer Profile </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="trainer-profile-list"
              :class="{ show: isActive(['/admin/trainer-profile']) }"
            >
              <ul class="nav-second-level">
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-profile' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-profile' ||
                          $route.name == 'trainer-profile-detail',
                      }"
                    >
                      Trainer Profile
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-if="
              hasPermissions([
                'module-list',
                'module-group-list',
                'module-handover-list',
                'module-handover-approval-list',
                'module-deactivate-list',
              ])
            "
          >
            <a
              href="#module-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/modules',
                  '/admin/module-groups',
                  '/admin/module-handover',
                  '/admin/module-handover-approval',
                  '/admin/module-deactivate',
                ])
              "
            >
              <i class="fas fa-book-open"></i>
              <span> Module Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="module-management"
              :class="{
                show: isActive([
                  '/admin/modules',
                  '/admin/module-groups',
                  '/admin/module-handover',
                  '/admin/module-handover-approval',
                  '/admin/module-deactivate',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['module-list'])">
                  <router-link class="nav-link" :to="{ name: 'modules' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'module-create' ||
                          $route.name == 'module-update',
                      }"
                    >
                      Modules
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['module-group-list'])">
                  <router-link class="nav-link" :to="{ name: 'module-groups' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'module-group-create' ||
                          $route.name == 'module-group-update',
                      }"
                    >
                      Module Group
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['module-handover-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'module-handover' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'module-handover-create' ||
                          $route.name == 'module-handover-detail',
                      }"
                    >
                      Module Handover
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['module-handover-approval-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'module-handover-approval' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'module-handover-approval-update',
                      }"
                    >
                      Handover Approval
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['module-deactivate-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'module-deactivate' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'module-deactivate-update',
                      }"
                    >
                      Module Deactivation
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['yearly-training-plan-index'])">
            <a
              href="#yearly-plan-management"
              data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/yearly-training-plans'])"
            >
              <i class="mdi mdi-calendar-blank-multiple fs-3"></i>
              <span> Yearly Schedule</span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="yearly-plan-management"
              :class="{ show: isActive(['/admin/yearly-training-plans']) }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['yearly-training-plan-index'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'yearly-training-plan-index' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'yearly-training-plan-show' ||
                          $route.name == 'yearly-training-plan-create',
                      }"
                    >
                      Yearly Schedule Plan
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- v-if="hasPermissions(['monthly-plan-list', 'monthly-plan-enroll-index'])" -->
          <li>
            <!-- id="monthly_plan" -->
            <a
              href="#monthly-training-plan"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/monthly-plans',
                  '/admin/monthly-plan-calendar-view',
                ])
              "
            >
              <i class="mdi mdi-calendar-clock fs-3"></i>
              <span> Monthly Schedule</span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="monthly-training-plan"
              :class="{
                show: isActive([
                  '/admin/monthly-plans',
                  '/admin/monthly-plan-calendar-view',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['monthly-schedule-plan-list'])">
                  <!-- <li> -->
                  <router-link class="nav-link" :to="{ name: 'monthly-plans' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'monthly-plan-create' ||
                          $route.name == 'monthly-plans-view-lists',
                      }"
                    >
                      Monthly Schedule Plan
                    </span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'monthly-plan-calendar-view' }"
                  >
                    <span> Calendar View </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- v-if="hasPermissions(['enrollment-approval-list', 'enrollment-user-list'])" -->
          <li>
            <a
              href="#enrollment"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/enrollment-approval',
                  '/admin/enrollment-list',
                  '/admin/monthly-plans/to-enroll',
                  '/admin/enrollment-user-view',
                  '/admin/enroll',
                ])
              "
            >
              <i class="mdi mdi-border-color fs-3"></i>
              <span> Enrollment </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="enrollment"
              :class="{
                show: isActive([
                  '/admin/enrollment-approval',
                  '/admin/enrollment-list',
                  '/admin/monthly-plans/to-enroll',
                  '/admin/enrollment-user-view',
                  '/admin/enroll',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <!-- <li v-if="hasPermissions(['enrollment-approval'])"> -->
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'enrollment-approval' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'enrollment-approval-update',
                      }"
                    >
                      Enrollment Approval
                    </span>
                  </router-link>
                </li>
                <!-- v-if="hasPermissions(['enrollment-user-list'])" -->
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'enrollment-user-list' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'enrollment-user-view',
                      }"
                      >User Enrollment History</span
                    >
                  </router-link>
                </li>
                <!-- v-if="hasPermissions(['monthly-plan-enroll-index'])" -->
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'monthly-plan-enroll' }"
                  >
                    <span
                      :class="{ activeRoute: $route.name == 'enroll-create' }"
                      >User Enrollment</span
                    >
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-if="
              hasPermissions([
                'training-confirm-list',
                'training-attendance-list',
              ])
            "
          >
            <a
              href="#training-confirmation-plan"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/training-confirmation',
                  '/admin/training-attendance',
                ])
              "
            >
              <i class="fas fa-list"></i>
              <span> Training Confirmation </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="training-confirmation-plan"
              :class="{
                show: isActive([
                  '/admin/training-confirmation',
                  '/admin/training-attendance',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-confirmation' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'training-confirm-edit',
                      }"
                    >
                      Training Plan
                    </span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-attendance' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'attendance-confirm-edit',
                      }"
                    >
                      Attendance
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-if="
              hasPermissions([
                'training-history-list',
                'evaluation-history-list',
              ])
            "
          >
            <a
              href="#training-database"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/training-history',
                  '/admin/evaluation-history',
                ])
              "
            >
              <i class="mdi mdi-database fs-3"></i>
              <span> Training Database </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="training-database"
              :class="{
                show: isActive([
                  '/admin/training-history',
                  '/admin/evaluation-history',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['training-history-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-history' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'training-history',
                      }"
                    >
                      Training History
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['evaluation-history-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'evaluation-history' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'evaluation-history-detail',
                      }"
                    >
                      Evaluation History
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-if="
              hasPermissions([
                'training-confirm-list',
                'training-attendance-list',
              ])
            "
          >
            <a
              href="#weight-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/assessment-criteria',
                  '/admin/assessment-criteria-percentage',
                ])
              "
            >
              <i class="mdi mdi-view-module fs-3"></i>
              <span> Weight Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="weight-management"
              :class="{
                show: isActive([
                  '/admin/assessment-criteria',
                  '/admin/assessment-criteria-percentage',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'assessment-criteria-percentage' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'assessment-criteria-percentage-create' ||
                          $route.name == 'assessment-criteria-percentage-edit',
                      }"
                    >
                      Assessment Criteria Percentage
                    </span>
                  </router-link>
                </li>

                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'assessment-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'assessment-criteria-create' ||
                          $route.name == 'assessment-criteria-edit',
                      }"
                    >
                      Assessment Criteria
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-if="
              hasPermissions([
                'training-confirm-list',
                'training-attendance-list',
              ])
            "
          >
            <a
              href="#hrd-criteria"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/orientation-criteria',
                  '/admin/assessment-criteria-percentage',
                ])
              "
            >
              <i class="mdi mdi-view-module fs-3"></i>
              <span> HRD Criteria </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="hrd-criteria"
              :class="{
                show: isActive([
                  '/admin/orientation-criteria',
                  '/admin/ojt-criteria',
                  '/admin/hrd-behavior-question',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'orientation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'orientation-criteria-create' ||
                          $route.name == 'orientation-criteria-update',
                      }"
                    >
                      Orientation Criteria
                    </span>
                  </router-link>
                </li>

                <li>
                  <router-link class="nav-link" :to="{ name: 'ojt-criteria' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'ojt-criteria-create' ||
                          $route.name == 'ojt-criteria-update',
                      }"
                    >
                      OJT Criteria
                    </span>
                  </router-link>
                </li>

                <li
                  v-if="
                    hasPermissions(['hrd-behaviour-question-criteria-list'])
                  "
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-behavior-question' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-behavior-question-create' ||
                          $route.name == 'hrd-behavior-question-update',
                      }"
                    >
                      Behavior Questions
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- Trainer Criteria -->

          <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#trainer-criteria-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/trainer-orientation-criteria',
                  '/admin/trainer-inclass-criteria',
                  '/admin/trainer-presentation-criteria',
                  '/admin/trainer-ojt-criteria',
                  '/admin/trainer-competency-criteria',
                  '/admin/trainer-evaluation-criteria',
                  '/admin/trainer-reflection-criteria',
                  '/admin/trainer-trainee-criteria',
                  '/admin/trainer-officer-credits',
                ])
              "
              style="display: flex; align-items: center"
            >
              <i class="mdi mdi-content-paste fs-3"></i>
              <span class="ms-1" > Trainer Assessment Criteria </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="trainer-criteria-management"
              :class="{
                show: isActive([
                  '/admin/trainer-orientation-criteria',
                  '/admin/trainer-inclass-criteria',
                  '/admin/trainer-presentation-criteria',
                  '/admin/trainer-ojt-criteria',
                  '/admin/trainer-competency-criteria',
                  '/admin/trainer-evaluation-criteria',
                  '/admin/trainer-reflection-criteria',
                  '/admin/trainer-trainee-criteria',
                  '/admin/trainer-officer-credits',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-orientation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'trainer-orientation-criteria-create' ||
                          $route.name == 'trainer-orientation-criteria-update',
                      }"
                    >
                      Orientation Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-inclass-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-inclass-criteria-create' ||
                          $route.name == 'trainer-inclass-criteria-update',
                      }"
                    >
                      InClass Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-presentation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'trainer-presentation-criteria-create' ||
                          $route.name == 'trainer-presentation-criteria-update',
                      }"
                    >
                      Presentation Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-ojt-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-ojt-criteria-create' ||
                          $route.name == 'trainer-ojt-criteria-update',
                      }"
                    >
                      OJT Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-competency-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-competency-criteria-create' ||
                          $route.name == 'trainer-competency-criteria-update',
                      }"
                    >
                      Competency Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-evaluation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-evaluation-criteria-create' ||
                          $route.name == 'trainer-evaluation-criteria-update',
                      }"
                    >
                      Evaluation Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-reflection-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-reflection-criteria-create' ||
                          $route.name == 'trainer-reflection-criteria-update',
                      }"
                    >
                      Reflection Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-trainee-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-trainee-criteria-create' ||
                          $route.name == 'trainer-trainee-criteria-update',
                      }"
                    >
                      Trainee-to-Trainer Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-officer-credits' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-officer-credits-create' ||
                          $route.name == 'trainer-officer-credits-update',
                      }"
                    >
                      Officer Credit
                    </span>
                  </router-link>
                </li>

                <!-- 
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-presentation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'trainer-presentation-criteria-create' ||
                          $route.name == 'trainer-presentation-criteria-update',
                      }"
                    >
                      Presentation Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-ojt-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-ojt-criteria-create' ||
                          $route.name == 'trainer-ojt-criteria-update',
                      }"
                    >
                      OJT Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-competency-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-competency-criteria-create' ||
                          $route.name == 'trainer-competency-criteria-update',
                      }"
                    >
                      Competency Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-evaluation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-evaluation-criteria-create' ||
                          $route.name == 'trainer-evaluation-criteria-update',
                      }"
                    >
                      Evaluation Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-reflection-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-reflection-criteria-create' ||
                          $route.name == 'trainer-reflection-criteria-update',
                      }"
                    >
                      Reflection Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-trainee-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-trainee-criteria-create' ||
                          $route.name == 'trainer-trainee-criteria-update',
                      }"
                    >
                      Trainee-to-Trainer Criteria
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-officer-credits' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-officer-credits-create' ||
                          $route.name == 'trainer-officer-credits-update',
                      }"
                    >
                      Officer Credit
                    </span>
                  </router-link>
                </li> -->
              </ul>
            </div>
          </li>

          <!-- <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#training-database"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/training-history',
                  '/admin/evaluation-history',
                ])
              "
            >
              <i class="mdi mdi-database fs-3"></i>
              <span> Training Database </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="training-database"
              :class="{
                show: isActive([
                  '/admin/training-history',
                  '/admin/evaluation-history',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-history' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'training-history',
                      }"
                    >
                      Training History
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'evaluation-history' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'evaluation-history-detail',
                      }"
                    >
                      Evaluation History
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#weight-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/evaluating-criteria',
                  '/admin/evaluating-weight',
                ])
              "
            >
              <i class="mdi mdi-view-module fs-3"></i>
              <span> Weight Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="weight-management"
              :class="{
                show: isActive([
                  '/admin/evaluating-criteria',
                  '/admin/evaluating-weight',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'evaluating-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'evaluating-criteria-create' ||
                          $route.name == 'evaluating-criteria-update',
                      }"
                    >
                      Evaluating Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'evaluating-weight' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'evaluating-weight-create' ||
                          $route.name == 'evaluating-weight-update',
                      }"
                    >
                      Evaluating Weight
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#hrd-criteria-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/hrd-orientation-criteria',
                  '/admin/hrd-ojt-criteria',
                  '/admin/hrd-behavior-question',
                ])
              "
            >
              <i class="mdi mdi-clipboard-text fs-3"></i>
              <span> HRD Criteria </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="hrd-criteria-management"
              :class="{
                show: isActive([
                  '/admin/hrd-orientation-criteria',
                  '/admin/hrd-ojt-criteria',
                  '/admin/hrd-behavior-question',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-orientation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-orientation-criteria-create' ||
                          $route.name == 'hrd-orientation-criteria-update',
                      }"
                    >
                      Orientation Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-ojt-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-ojt-criteria-create' ||
                          $route.name == 'hrd-ojt-criteria-update',
                      }"
                    >
                      OJT Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-behavior-question' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-behavior-question-create' ||
                          $route.name == 'hrd-behavior-question-update',
                      }"
                    >
                      Behavior Questions
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a href="#trainer-criteria-management" data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/trainer-orientation-criteria', '/admin/trainer-inclass-criteria', '/admin/trainer-presentation-criteria', '/admin/trainer-ojt-criteria', '/admin/trainer-competency-criteria', '/admin/trainer-evaluation-criteria', '/admin/trainer-reflection-criteria', '/admin/trainer-trainee-criteria', '/admin/trainer-officer-credits'])">
              <i class="mdi mdi-content-paste fs-3"></i>
              <span> Trainer Criteria </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="trainer-criteria-management"
              :class="{
                show: isActive([
                  '/admin/trainer-orientation-criteria',
                  '/admin/trainer-inclass-criteria',
                  '/admin/trainer-presentation-criteria',
                  '/admin/trainer-ojt-criteria',
                  '/admin/trainer-competency-criteria',
                  '/admin/trainer-evaluation-criteria',
                  '/admin/trainer-reflection-criteria',
                  '/admin/trainer-trainee-criteria',
                  '/admin/trainer-officer-credits',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-orientation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'trainer-orientation-criteria-create' ||
                          $route.name == 'trainer-orientation-criteria-update',
                      }"
                    >
                      Orientation Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-inclass-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-inclass-criteria-create' ||
                          $route.name == 'trainer-inclass-criteria-update',
                      }"
                    >
                      InClass Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-presentation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'trainer-presentation-criteria-create' ||
                          $route.name == 'trainer-presentation-criteria-update',
                      }"
                    >
                      Presentation Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-ojt-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-ojt-criteria-create' ||
                          $route.name == 'trainer-ojt-criteria-update',
                      }"
                    >
                      OJT Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-competency-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-competency-criteria-create' ||
                          $route.name == 'trainer-competency-criteria-update',
                      }"
                    >
                      Competency Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-evaluation-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-evaluation-criteria-create' ||
                          $route.name == 'trainer-evaluation-criteria-update',
                      }"
                    >
                      Evaluation Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-reflection-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-reflection-criteria-create' ||
                          $route.name == 'trainer-reflection-criteria-update',
                      }"
                    >
                      Reflection Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-trainee-criteria' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-trainee-criteria-create' ||
                          $route.name == 'trainer-trainee-criteria-update',
                      }"
                    >
                      Trainee-to-Trainer Criteria
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-officer-credits' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-officer-credits-create' ||
                          $route.name == 'trainer-officer-credits-update',
                      }"
                    >
                      Officer Credit
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#schedule-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/program-timeline',
                  '/admin/program-allocation',
                  '/admin/orientation-assessment',
                  '/admin/ojt-assessment',
                ])
              "
            >
              <i class="mdi mdi-clock-fast fs-3"></i>
              <span> Schedule Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="schedule-management"
              :class="{
                show: isActive([
                  '/admin/program-timeline',
                  '/admin/program-allocation',
                  '/admin/orientation-assessment',
                  '/admin/ojt-assessment',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'program-timeline' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'program-timeline-create' ||
                          $route.name == 'program-timeline-update',
                      }"
                    >
                      Program Timeline
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'program-allocation' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'program-allocation-create' ||
                          $route.name == 'program-allocation-update',
                      }"
                    >
                      Program Allocation
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'orientation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'orientation-assessment-create' ||
                          $route.name == 'orientation-assessment-update',
                      }"
                    >
                      Orientation Assessment
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'ojt-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'ojt-assessment-create' ||
                          $route.name == 'ojt-assessment-update',
                      }"
                    >
                      OJT Assessment
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#training-database"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/training-history',
                  '/admin/evaluation-history',
                ])
              "
            >
              <i class="mdi mdi-database fs-3"></i>
              <span> Training Database </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="training-database"
              :class="{
                show: isActive([
                  '/admin/training-history',
                  '/admin/evaluation-history',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-history' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'training-history',
                      }"
                    >
                      Training History
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['program-owner-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'evaluation-history' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'evaluation-history-detail',
                      }"
                    >
                      Evaluation History
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['trainer-list'])">
            <a
              href="#batch-management"
              data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/orientation-batch'])"
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Batch Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="batch-management"
              :class="{ show: isActive(['/admin/orientation-batch']) }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'orientation-batch' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'orientation-batch',
                      }"
                    >
                      Orientation Batch
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>  -->

          <!---------------------  HRD ASSESSMENT  ------------------------------->
          <!-- <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#hrd-assessment"
              data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/hrd-orientation-assessment','admin/hrd-ojt-assessment','admin/hrd-behavior-assessment','admin/hrd-behavior-evaluation','admin/hrd-orientation-evaluation'])"
            >
              <i class="mdi mdi-clipboard-text fs-3"></i>
              <span>HRD Assessment </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="hrd-assessment"
              :class="{
                show: isActive([
                '/admin/hrd-orientation-assessment','admin/hrd-ojt-assessment','admin/hrd-behavior-assessment','admin/hrd-behavior-evaluation','admin/hrd-orientation-evaluation'
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-orientation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-orientation-assessment-getEditId' ||
                          $route.name == 'hrd-orientation-assessment-update',
                      }"
                    >
                      Orientation Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-ojt-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-ojt-assessment-getEditId' ||
                          $route.name == 'hrd-ojt-assessment-update',
                      }"
                    >
                      OJT Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-behavior-assessment' }"
                  >
                    <span>
                      Behavior Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-behavior-evaluation' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-behavior-evaluation-create' ||
                          $route.name == 'hrd-behavior-evaluation-update',
                      }"
                    >
                      Behavior Evaluation
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-orientation-evaluation' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-orientation-evaluation-getEditId' ||
                          $route.name == 'hrd-orientation-evaluation-update',
                      }"
                    >
                      Orientation Evaluation
                    </span>
                  </router-link>
                </li>

              </ul>
            </div>
          </li> -->

          <!-------------   Trainer Assessment    -------------------------->
          <!-- <li v-if="hasPermissions(['trainer-list'])">
            <a
              href="#trainer-assessment"
              data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/trainer-orientation-assessment', 'admin/trainer-inclass-assessment'])"
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Trainer Assessment </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="trainer-assessment"
              :class="{ show: isActive(['/admin/trainer-orientation-assessment', 'admin/trainer-inclass-assessment']) }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-orientation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'trainer-orientation-assessment-create',
                        activeRoute: $route.name == 'trainer-orientation-assessment-update',
                      }"
                    >
                      Orientation Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-inclass-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'trainer-inclass-assessment-create',
                        activeRoute: $route.name == 'trainer-inclass-assessment-update',
                      }"
                    >
                      Inclass Assessment
                    </span>
                  </router-link>
                </li>

              </ul>
            </div>
          </li>  -->
          <li v-if="hasPermissions(['trainer-list', 'program-owner-list'])">
            <a
              href="#schedule-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/program-timeline',
                  '/admin/program-allocation',
                  '/admin/orientation-assessment',
                  '/admin/ojt-assessment',
                ])
              "
            >
              <i class="mdi mdi-clock-fast fs-3"></i>
              <span> Schedule Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="schedule-management"
              :class="{
                show: isActive([
                  '/admin/program-timeline',
                  '/admin/program-allocation',
                  '/admin/orientation-assessment',
                  '/admin/ojt-assessment',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'program-timeline' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'program-timeline-create' ||
                          $route.name == 'program-timeline-update',
                      }"
                    >
                    Program History Timeline
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'program-allocation' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'program-allocation-create' ||
                          $route.name == 'program-allocation-update',
                      }"
                    >
                    Program Allocation Timeline
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'orientation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'orientation-assessment-create' ||
                          $route.name == 'orientation-assessment-update',
                      }"
                    >
                      Orientation Assessment
                    </span>
                  </router-link>
                </li>
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'ojt-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'ojt-assessment-create' ||
                          $route.name == 'ojt-assessment-update',
                      }"
                    >
                      OJT Assessment
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="hasPermissions(['trainer-list'])">
            <a
              href="#batch-management"
              data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/orientation-batch'])"
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Batch Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="batch-management"
              :class="{ show: isActive(['/admin/orientation-batch']) }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['trainer-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'orientation-batch' }"
                  >
                    <span
                      :class="{
                        activeRoute: $route.name == 'orientation-batch',
                      }"
                    >
                      Orientation Batch
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>


           <!---------------------  HRD ASSESSMENT  ------------------------------->
           <li
            v-if="
              hasPermissions([
                'hrd-orientation-assessment-list',
                'hrd-ojt-assessment-list',
                'hrd-behaviour-assessment-list',
                'hrd-orientation-evaluation-list',
                'hrd-behaviour-evaluation-list',
              ])
            "
          >
            <a
              href="#hrd-assessment"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/hrd-orientation-assessment',
                  '/admin/hrd-ojt-assessment',
                  '/admin/hrd-functional-assessment',
                  '/admin/hrd-ruleregulation-assessment',
                  '/admin/hrd-behavior-assessment',
                  '/admin/hrd-behavior-evaluation',
                  '/admin/hrd-orientation-evaluation',
                ])
              "
            >
              <i class="mdi mdi-clipboard-text fs-3"></i>
              <span>HRD Assessment </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="hrd-assessment"
              :class="{
                show: isActive([
                  '/admin/HRD',
                  '/admin/hrd-ojt-assessment',
                  '/admin/hrd-functional-assessment',
                  '/admin/hrd-ruleregulation-assessment',
                  '/admin/hrd-behavior-assessment',
                  '/admin/hrd-behavior-evaluation',
                  '/admin/hrd-orientation-evaluation',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['hrd-orientation-assessment-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-orientation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'hrd-orientation-assessment-getEditId' ||
                          $route.name == 'hrd-orientation-assessment-update',
                      }"
                    >
                      Orientation Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['hrd-ojt-assessment-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-ojt-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-ojt-assessment-getEditId' ||
                          $route.name == 'hrd-ojt-assessment-update',
                      }"
                    >
                      OJT Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['hrd-ojt-assessment-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-functional-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-functional-assessment-getEditId' ||
                          $route.name == 'hrd-functional-assessment-update',
                      }"
                    >
                      Functional Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['hrd-ojt-assessment-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-ruleregulation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-ruleregulation-assessment-getEditId' ||
                          $route.name == 'hrd-ruleregulation-assessment-update',
                      }"
                    >
                      Rule & Regulation Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['hrd-behaviour-assessment-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-behavior-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-behavior-assessment-detail',
                      }"
                    >
                      Behavior Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['hrd-behaviour-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-behavior-evaluation' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-behavior-evaluation-create' ||
                          $route.name == 'hrd-behavior-evaluation-update',
                      }"
                    >
                      Behavior Evaluation
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['hrd-orientation-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-orientation-evaluation' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'hrd-orientation-evaluation-create' ||
                          $route.name == 'hrd-orientation-evaluation-update',
                      }"
                    >
                      Orientation Evaluation
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-------------   Trainer Assessment    -------------------------------->
          <li
            v-if="
              hasPermissions([
                'trainer-orientation-assessment-list',
                'trainer-inclass-assessment-list',
                'trainer-presentation-assessment-list',
                'trainer-ojt-assessment-list',
                'trainer-compentency-assessment-list',
                'trainer-orientation-award-list',
                'trainer-peer-to-peer-assessment-list',
                'trainer-training-reflection-list',
                'trainer-trainee-to-trainer-assessment-list',
              ])
            "
          >
            <a
              href="#trainer-assessment"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/trainer-orientation-assessment',
                  '/admin/trainer-inclass-assessment',
                  '/admin/trainer-presentation-assessment',
                  '/admin/trainer-ojt-assessment',
                  '/admin/trainer-ojt-assessment-schedule',
                  '/admin/trainer-competency-assessment',
                  '/admin/orientation-award',
                  '/admin/peer-to-peer-assessment',
                  '/admin/trainee-to-trainer-assessment',
                  '/admin/training-reflection',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Trainer Assessment </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="trainer-assessment"
              :class="{
                show: isActive([
                  '/admin/trainer-orientation-assessment',
                  '/admin/trainer-inclass-assessment',
                  '/admin/trainer-presentation-assessment',
                  '/admin/trainer-ojt-assessment',
                  '/admin/trainer-ojt-assessment-schedule',
                  '/admin/trainer-competency-assessment',
                  '/admin/orientation-award',
                  '/admin/peer-to-peer-assessment',
                  '/admin/trainee-to-trainer-assessment',
                  '/admin/training-reflection',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li
                  v-if="hasPermissions(['trainer-orientation-assessment-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-orientation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'trainer-orientation-assessment-create' ||
                          $route.name ==
                            'trainer-orientation-assessment-update',
                      }"
                    >
                      Orientation Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-inclass-assessment-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-inclass-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-inclass-assessment-create' ||
                          $route.name == 'trainer-inclass-assessment-update',
                      }"
                    >
                      Inclass Assessment
                    </span>
                  </router-link>
                </li>

                <li
                  v-if="
                    hasPermissions(['trainer-presentation-assessment-list'])
                  "
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-presentation-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'trainer-presentation-assessment-create' ||
                          $route.name ==
                            'trainer-presentation-assessment-update',
                      }"
                    >
                      Presentation Assessment
                    </span>
                  </router-link>
                </li>

             

                <li
                  v-if="hasPermissions(['superior-assessment-schedule-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-ojt-assessment-schedule' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'superior-assessment-schedule-create' ||
                          $route.name == 'superior-assessment-schedule-edit',
                      }"
                    >
                      Superior Assessment Schedule
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-ojt-assessment-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-ojt-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-ojt-assessment-detail',
                      }"
                    >
                      Superior Assessment
                    </span>
                  </router-link>
                </li>

                <li
                  v-if="hasPermissions(['trainer-compentency-assessment-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainer-competency-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainer-competency-assessment-detail',
                      }"
                    >
                      Competency Assessment
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-orientation-award-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'orientation-award' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'orientation-award-create' ||
                          $route.name == 'orientation-award-update',
                      }"
                    >
                      Orientation Award
                    </span>
                  </router-link>
                </li>

                <li
                  v-if="
                    hasPermissions(['trainer-peer-to-peer-assessment-list'])
                  "
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'peer-to-peer-assessment' }"
                  >
                    <span> Peer to Peer Assessment </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['trainer-training-reflection-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-reflection' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'training-reflection-create' ||
                          $route.name == 'training-reflection-update',
                      }"
                    >
                      Training Reflection
                    </span>
                  </router-link>
                </li>

                <li
                  v-if="
                    hasPermissions([
                      'trainer-trainee-to-trainer-assessment-list',
                    ])
                  "
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'trainee-to-trainer-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'trainee-to-trainer-assessment-detail',
                      }"
                    >
                      Trainee-to-Trainer Assessment
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

            <!-------------  After Trainer Assessment    ---------------------------->
            <li
            v-if="
              hasPermissions([
                'ata-question-list',
                'ata-assessment-module-list',
                'ata-assessment-timeline-list',
                'ata-assessment-result-list',
              ])
            "
          >
            <a
              href="#after-trainer-assessment"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/after-trainer-question-assessment',
                  '/admin/after-trainer-question-assessment/create',
                  '/admin/after-trainer-question-assessment/edit',
                  '/admin/assessment-module',
                  '/admin/assessment-module/create',
                  '/admin/assessment-module/edit',
                  '/admin/assessment-timeline',
                  'admin/assessment-timeline/edit',
                  '/admin/assessment-result',
                  'admin/assessment-result/edit',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span>After Trainer Assessment </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="after-trainer-assessment"
              :class="{
                show: isActive([
                  '/admin/after-trainer-question-assessment',
                  '/admin/after-trainer-question-assessment/create',
                  '/admin/after-trainer-question-assessment/edit',
                  '/admin/assessment-module',
                  '/admin/assessment-module/create',
                  '/admin/assessment-module/edit',
                  '/admin/assessment-timeline',
                  'admin/assessment-timeline/edit',
                  '/admin/assessment-result',
                  'admin/assessment-result/edit',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['ata-question-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'after-trainer-question-assessment' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'after-trainer-question-assessment' ||
                          $route.name ==
                            'after-trainer-question-assessment/create' ||
                          $route.name ==
                            'after-trainer-question-assessment/edit',
                      }"
                    >
                      Question Create Form
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['ata-assessment-module-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'assessment-module' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'assessment-module' ||
                          $route.name == 'assessment-module/create' ||
                          $route.name == 'assessment-module/edit',
                      }"
                    >
                      Assessment Create Form
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['ata-assessment-timeline-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'assessment-timeline' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'assessment-timeline' ||
                          $route.name == 'assessment-timeline/edit',
                      }"
                    >
                      Assessment Timeline
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['ata-assessment-result-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'assessment-result' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'assessment-result' ||
                          $route.name == 'assessment-result/edit',
                      }"
                    >
                      Superior/Hr Assessment Result
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-------------   Evaluation Management    -------------------------->
          <li
            v-if="
              hasPermissions([
                'orientation-evaluation-list',
                'inclass-evaluation-list',
                'presentation-evaluation-list',
                'ojt-evaluation-list',
                'compentency-evaluation-list',
              ])
            "
          >
            <a
              href="#evaluation-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/orientation-evaluation',
                  '/admin/inclass-evaluation-management',
                  '/admin/presentation-evaluation-management',
                  '/admin/ojt-evaluation-management',
                  '/admin/competency-evaluation-management',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Evaluation Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="evaluation-management"
              :class="{
                show: isActive([
                  '/admin/orientation-evaluation-management',
                  '/admin/inclass-evaluation-management',
                  '/admin/presentation-evaluation-management',
                  '/admin/ojt-evaluation-management',
                  '/admin/competency-evaluation-management',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['orientation-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'orientation-evaluation-management' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'orientation-evaluation-management-create' ||
                          $route.name ==
                            'orientation-evaluation-management-update',
                      }"
                    >
                      Orientation Evaluation
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['inclass-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'inclass-evaluation-management' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'inclass-evaluation-management-create' ||
                          $route.name == 'inclass-evaluation-management-update',
                      }"
                    >
                      Inclass Evaluation
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['presentation-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'presentation-evaluation-management' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'presentation-evaluation-management-create' ||
                          $route.name ==
                            'presentation-evaluation-management-update',
                      }"
                    >
                      Presentation Evaluation
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['ojt-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'ojt-evaluation-management' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'ojt-evaluation-management-create' ||
                          $route.name == 'ojt-evaluation-management-update',
                      }"
                    >
                      OJT Evaluation
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['compentency-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'competency-evaluation-management' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'competency-evaluation-management-create' ||
                          $route.name ==
                            'competency-evaluation-management-update',
                      }"
                    >
                      Competency Evaluation
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <!-------------   Grading Management    -------------------------->
          <li
            v-if="
              hasPermissions(['grading-level-list', 'grading-evaluation-list'])
            "
          >
            <a
              href="#grading-evaluation-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/grading-level-management',
                  '/admin/grading-evaluation-management',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Grading Evaluation </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="grading-evaluation-management"
              :class="{
                show: isActive([
                  '/admin/grading-level-management',
                  '/admin/grading-evaluation-management',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['grading-level-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'grading-level-management' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'grading-level-management-create' ||
                          $route.name == 'grading-level-management-update',
                      }"
                    >
                      Grading Level
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['grading-evaluation-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'grading-evaluation-management' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'grading-evaluation-management-create' ||
                          $route.name == 'grading-evaluation-management-update',
                      }"
                    >
                      Grading Evaluation
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-----------------  Increment Management  ------------------------------->
          <li v-if="hasPermissions(['increment-management-list'])">
            <a
              href="#increment-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/increment-management-request',
                  '/admin/increment-management-approval',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Increment Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="increment-management"
              :class="{
                show: isActive([
                  '/admin/increment-management-request',
                  '/admin/increment-management-approval',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['increment-management-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'increment-management-request' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'increment-management-request-create' ||
                          $route.name == 'increment-management-request-update',
                      }"
                    >
                      Request
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['increment-management-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'increment-management-approval' }"
                  >
                    <span
                      :class="{
                        activeRoute:
                          $route.name ==
                            'increment-management-approval-detail' ||
                          $route.name == 'increment-management-approval-save',
                      }"
                    >
                      Approval
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <!------------------  Reporting   ----------------------------------->
          <li
            class="menu-title mt-2"
            v-if="
              hasPermissions([
                'hrd-orientation-assessment-report-list',
                'hrd-ojt-assessment-report-list',
                'hrd-orientation-individual-assessment-report-list',
                'hrd-ojt-individual-assessment-report-list',
                'hrd-assessment-schedule-report-list',
              ])
            "
          >
            Reporting
          </li>

          <!-----------------  HRD  ------------------------------->
          <li
            v-if="
              hasPermissions([
                'hrd-orientation-assessment-report-list',
                'hrd-ojt-assessment-report-list',
                'hrd-orientation-individual-assessment-report-list',
                'hrd-ojt-individual-assessment-report-list',
                'hrd-assessment-schedule-report-list',
              ])
            "
          >
            <a
              href="#hrd-orientation-assessment-report"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/hrd-orientation-assessment-report',
                  '/admin/hrd-ojt-assessment-report',
                  '/admin/individual-orientation-assessment-report',
                  '/admin/individual-ojt-assessment-report',
                  '/admin/assessment-schedule-report',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> HRD </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="hrd-orientation-assessment-report"
              :class="{
                show: isActive([
                  '/admin/hrd-orientation-assessment-report',
                  '/admin/hrd-ojt-assessment-report',
                  '/admin/individual-orientation-assessment-report',
                  '/admin/individual-ojt-assessment-report',
                  '/admin/assessment-schedule-report',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li
                  v-if="
                    hasPermissions(['hrd-orientation-assessment-report-list'])
                  "
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-orientation-assessment-report' }"
                  >
                    Orientation Assessment
                  </router-link>
                </li>

                <li v-if="hasPermissions(['hrd-ojt-assessment-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'hrd-ojt-assessment-report' }"
                  >
                    OJT Assessment
                  </router-link>
                </li>

                <li
                  v-if="
                    hasPermissions([
                      'hrd-orientation-individual-assessment-report-list',
                    ])
                  "
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'individual-orientation-assessment-report' }"
                  >
                    Individual Orientation Assessment
                  </router-link>
                </li>

                <li
                  v-if="
                    hasPermissions([
                      'hrd-ojt-individual-assessment-report-list',
                    ])
                  "
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'individual-ojt-assessment-report' }"
                  >
                    Individual OJT Assessment
                  </router-link>
                </li>

                <li
                  v-if="hasPermissions(['hrd-assessment-schedule-report-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'assessment-schedule-report' }"
                  >
                    Assessment Schedule
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-if="
              hasPermissions([
                'module-cancel-report-list',
                'number-of-classes-report-list',
                'training-attendance-report-list',
                'enrollment-list-report-list',
                'cancel-man-power-report-list',
                'monthly-training-plan-report-list',
                'training-database-report-list',
                'evaluation-achievement-report-list',
                'evaluation-result-report-list',
                'evaluation-suggestion-report-list',
              ])
            "
          >
            <a
              href="#training-module-cancel-report"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/training-module-cancel-report',
                  '/admin/training-monthly-training-plan-report',
                  '/admin/training-class-report',
                  '/admin/training-enrollment-list-report',
                  '/admin/cancel-menpower-report',
                  '/admin/training-database-list-report',
                  '/admin/training-evaluation-achievement-report',
                  '/admin/training-evaluation-suggestion-report',
                  '/admin/training-attendance-report',
                  '/admin/training-evaluation-result-report',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Training </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="training-module-cancel-report"
              :class="{
                show: isActive([
                  '/admin/training-module-cancel-report',
                  '/admin/training-monthly-training-plan-report',
                  '/admin/training-class-report',
                  '/admin/training-enrollment-list-report',
                  '/admin/cancel-menpower-report',
                  '/admin/training-database-list-report',
                  '/admin/training-evaluation-achievement-report',
                  '/admin/training-attendance-report',
                  '/admin/training-evaluation-suggestion-report',
                  '/admin/training-evaluation-result-report',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['training-attendance-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-attendance-report' }"
                  >
                    Total Attendance
                  </router-link>
                </li>
                <li v-if="hasPermissions(['number-of-classes-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-class-report' }"
                  >
                    Number Of Class
                  </router-link>
                </li>

                <li v-if="hasPermissions(['enrollment-list-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-enrollment-list-report' }"
                  >
                    Enrollment List
                  </router-link>
                </li>

                <li v-if="hasPermissions(['cancel-man-power-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'cancel-menpower-report' }"
                  >
                    Cancel Man Power Report
                  </router-link>
                </li>

                <li v-if="hasPermissions(['module-cancel-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-module-cancel-report' }"
                  >
                    Module Cancel
                  </router-link>
                </li>

                <li v-if="hasPermissions(['training-database-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-database-list-report' }"
                  >
                    Training Evaluation Report
                  </router-link>
                </li>

                <li
                  v-if="hasPermissions(['evaluation-achievement-report-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-evaluation-achievement-report' }"
                  >
                    Evaluation Achievement Report
                  </router-link>
                </li>

                <li v-if="hasPermissions(['evaluation-result-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-evaluation-result-report' }"
                  >
                    Evaluation Result Report
                  </router-link>
                </li>

                <li
                  v-if="hasPermissions(['evaluation-suggestion-report-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-evaluation-suggestion-report' }"
                  >
                    Evaluation Suggestion Report
                  </router-link>
                </li>

                <li
                  v-if="hasPermissions(['monthly-training-plan-report-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'training-monthly-training-plan-report' }"
                  >
                    Monthly Plan Training
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!--------------------  AFTER TRAINING ASSESSMENT  ------------->
          <li
            v-if="
              hasPermissions([
                'ata-summary-report-list',
                'ata-individual-report-list',
                'ata-achievement-detail-report-list',
              ])
            "
          >
            <a
              href="#after-training-assessment-report"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive([
                  '/admin/ata-summary-report',
                  '/admin/ata-individual-report',
                  '/admin/achievement-detail-report',
                ])
              "
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> ATA </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="after-training-assessment-report"
              :class="{
                show: isActive([
                  '/admin/ata-summary-report',
                  '/admin/ata-individual-report',
                  '/admin/achievement-detail-report',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['ata-summary-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'ata-summary-report' }"
                  >
                    ATA Summary Report
                  </router-link>
                </li>

                <li v-if="hasPermissions(['ata-individual-report-list'])">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'ata-individual-report' }"
                  >
                    ATA Individual Report
                  </router-link>
                </li>

                <li
                  v-if="hasPermissions(['ata-achievement-detail-report-list'])"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'achievement-detail-report' }"
                  >
                    Achievement Detail Report
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

           <!--------------------  ENROLL LIST   -------------------->

           <li v-if="$store.state.auth.user.roles[0] == 'Super Admin'">
            <a
              href="#enroll-usage-count-report"
              data-bs-toggle="collapse"
              :aria-expanded="isActive(['/admin/enroll-usage-count-report'])"
            >
              <i class="mdi mdi-archive fs-3"></i>
              <span> Enrollment </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="enroll-usage-count-report"
              :class="{
                show: isActive(['/admin/enroll-usage-count-report']),
              }"
            >
              <ul class="nav-second-level">
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'enroll-usage-count-report' }"
                  >
                    Enroll Usage Count
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li
            class="menu-title mt-2"
            v-if="hasPermissions(['role-list', 'permission-list', 'user-list'])"
          >
            Administration
          </li>

          <li
            v-if="hasPermissions(['role-list', 'permission-list', 'user-list'])"
          >
            <a
              href="#user-management"
              data-bs-toggle="collapse"
              :aria-expanded="
                isActive(['/admin/users', '/admin/roles', '/admin/permissions'])
              "
            >
              <i class="fe-users"></i>
              <span> User Management </span>
              <span class="menu-arrow"></span>
            </a>
            <div
              class="collapse"
              id="user-management"
              :class="{
                show: isActive([
                  '/admin/users',
                  '/admin/roles',
                  '/admin/permissions',
                ]),
              }"
            >
              <ul class="nav-second-level">
                <li v-if="hasPermissions(['user-list'])">
                  <router-link class="nav-link" :to="{ name: 'users' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'user-create' ||
                          $route.name == 'user-update',
                      }"
                    >
                      Users
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['role-list'])">
                  <router-link class="nav-link" :to="{ name: 'roles' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'role-create' ||
                          $route.name == 'role-update',
                      }"
                    >
                      Roles
                    </span>
                  </router-link>
                </li>

                <li v-if="hasPermissions(['permission-list'])">
                  <router-link class="nav-link" :to="{ name: 'permissions' }">
                    <span
                      :class="{
                        activeRoute:
                          $route.name == 'permission-create' ||
                          $route.name == 'permission-update',
                      }"
                    >
                      Permissions
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- End Sidebar -->

      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  name: "Sidebar",
  mixins: [userHasPermissions],

  mounted() {
    // console.log(this.$router.currentRoute.value.name, "sidebar route");
    // if (this.$router.currentRoute.value.name == "trainers") {
    //   document.getElementById("trainers").style.color = "#00acc1";
    // }
    // if (this.$router.currentRoute.value.name == "monthly-plan-calendar-view") {
    //   document.getElementById("monthly_plan").style.color = "#00acc1"
    // }
  },
  methods: {
    isActive(paths) {
      return paths.find((data) => {
        return this.$route.path.startsWith(data);
      });
    },
  },
};
</script>

<style scoped>
.activeRoute {
  color: #00acc1;
}
</style>
