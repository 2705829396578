<template>
  <div v-if="isInitialized" class="content-page">
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- content -->

    <!-- Footer Start -->
    <footer class="footer" style="left: 250px;">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            {{ currentYear }}
            &copy; TIS-Internal-APP by <a href="#">Web Team(CID)</a>
          </div>
          <!-- <div class="col-md-6">
                        <div class="text-md-end footer-links d-none d-sm-block">
                            <a href="javascript:void(0);">About Us</a>
                            <a href="javascript:void(0);">Help</a>
                            <a href="javascript:void(0);">Contact Us</a>
                        </div>
                    </div> -->
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <div v-else class="loading">Loading...</div>
</template>

<script>
export default {
  name: "Content",
  data() {
    return {
      isInitialized: false,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  async created() {
    try {
      const employees = this.$store.getters["odoo/getAllEmployees"];
      console.log(employees.length);
      if (employees.length === 0) {
        await this.$store.commit("odoo/fetchAllEmployees");
        setTimeout(() => {
          this.isInitialized = true;
        }, 500);
      }
      
      
    } catch (error) {
      console.error("Error fetching employees data:", error);
    }
  },
};
</script>

<style>
.loading {
  text-align: center;
  padding: 50px;
  font-size: 20px;
  color: #888;
}
</style>
